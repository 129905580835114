function offCanvasOpen() {
    let offCanvasTriggers = document.querySelectorAll("[data-offcanvas-open]");

    offCanvasTriggers.forEach((offCanvasTrigger) => {
        offCanvasTrigger.addEventListener("click", () => {
            console.log(offCanvasTrigger.dataset.offcanvasOpen);
            let offCanvasItem = document.getElementById(offCanvasTrigger.dataset.offcanvasOpen);
            offCanvasItem.classList.add("off-canvas-open");
        });
    });
}

function offCanvasClose() {
    let offCanvasTriggers = document.querySelectorAll("[data-offcanvas-close]");

    offCanvasTriggers.forEach((offCanvasTrigger) => {
        offCanvasTrigger.addEventListener("click", () => {
            let offCanvasItem = document.getElementById(offCanvasTrigger.dataset.offcanvasClose);
            offCanvasItem.classList.remove("off-canvas-open");
        });
    });
}

function offcanvasDropdown() {
    let offcanvasMenu = document.querySelector(".ns-off-canvas-menu");
    let dropdownItems = offcanvasMenu.querySelectorAll(".menu-item-has-children");

    dropdownItems.forEach((dropdownItem) => {
        let timesClicked = 0;
        dropdownItem.addEventListener("click", () => {
            dropdownItem.classList.toggle("sub-menu-open");
        });
        dropdownItem.querySelector("a").addEventListener("click", (e) => {
            timesClicked++;
            if (timesClicked <= 1) {
                e.preventDefault();
            } else {
                console.log("this is fine");
            }
        });
    });
}

export { offCanvasOpen, offCanvasClose, offcanvasDropdown };
