//w3 school collapsable
var coll = document.getElementsByClassName("collapsible");
var i;

for (i = 0; i < coll.length; i++) {
	coll[i].addEventListener("click", function () {
		this.classList.toggle("active");
		var content = this.nextElementSibling;
		if (content.style.display === "block") {
			content.style.display = "none";
		} else {
			var allCollapsibles = document.getElementsByClassName("collapsible");
			for (var j = 0; j < allCollapsibles.length; j++) {
				var otherContent = allCollapsibles[j].nextElementSibling;
				if (otherContent.style.display === "block") {
					allCollapsibles[j].classList.remove("active");
					otherContent.style.display = "none";
				}
			}
			content.style.display = "block";
		}
	});
}
