import { offCanvasOpen, offCanvasClose, offcanvasDropdown } from "./modules/offcanvas";
import { nsCounterInput } from "./modules/counter-input";
//import { animationObserver } from "./modules/animation-observer";

import "./modules/accordion";

offCanvasOpen();
offCanvasClose();
offcanvasDropdown();

if (document.querySelector(".product-quantity input.qty")) {
	nsCounterInput(".product-quantity input.qty", 0, 100);
	(function ($) {
		$(document.body).on("updated_cart_totals", function () {
			nsCounterInput(".product-quantity input.qty", 0, 100);
		});
	})(jQuery);
}

// Grid/List View
//get elements
var listContainer = document.querySelector("#course-list");
var listViewBtn = document.querySelector("#list-view");
var gridViewBtn = document.querySelector("#grid-view");

if (listViewBtn || gridViewBtn) {
	listViewBtn.addEventListener("click", listView);
	gridViewBtn.addEventListener("click", gridView);
	// List View
	function listView() {
		console.log("list view");
		listContainer.classList.add("list-view");
		listContainer.classList.remove("grid-view");
	}

	// Grid View
	function gridView() {
		console.log("Grid view");
		listContainer.classList.add("grid-view");
		listContainer.classList.remove("list-view");
	}
}

//set booking data on cehckout form
let checkoutFormDataField = document.querySelector(".pd-booking-data textarea");

if (checkoutFormDataField) {
	checkoutFormDataField.readonly = false;
	checkoutFormDataField.value = localStorage.getItem("cart");
	checkoutFormDataField.readonly = true;
}
